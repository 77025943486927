import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'

const Error = ({ data }) => {
  const heroImage = data.heroImage
  return (
    <>
      <Layout>
        <div id="sub-page">
          <SEO
            title="Error 404"
            keywords={[
              `eden`,
              `UNICOM Global`,
              `Real Estate`,
              `UNICOM Technology Park`,
              `UNICOM Science & Technology Park`,
              `PickFair`,
              `Wingsweep`,
              `Roripaugh Ranch`,
              `Business and Financial Services`,
              `Mergers and Acquisitions`,
            ]}
          />
          <Hero
            class="intro-100"
            image={heroImage.childImageSharp.gatsbyImageData}
            title="Error 404"
            subtitle="Oops, we couldn't find the page you're looking for ...please go to our home page by clicking the button below."
            ctaslug="/"
            ctatext="Take me there"
            type="404"
          />
        </div>

        <main></main>
      </Layout>
    </>
  )
}
export default Error

export const query = graphql`
  query {
    heroImage: file(name: { eq: "404-not-found" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }
  }
`